import React from "react"
import { useLocation, LocationProvider } from "@reach/router"
import styles from "./alert-bar.module.scss"
import { AlertBarComponent } from "./alert-bar-component"

const PathAlertBar = () => {
  const { pathname } = useLocation()

  if (pathname.match(/\/learn/gim)) {
    return <AlertBarComponent location="learn" />
  }

  return null
}

export const AlertBar = () => {
  return (
    <LocationProvider>
      <div className={styles.container}>
        <AlertBarComponent location="site" />
        <PathAlertBar />
      </div>
    </LocationProvider>
  )
}
